export const GET_MAIN_PRIVILLAGE = "GET_MAIN_PRIVILLAGE"
export const GET_MAIN_PRIVILLAGE_SUCCESS = "GET_MAIN_PRIVILLAGE_SUCCESS"
export const GET_MAIN_PRIVILLAGE_FAIL = "GET_MAIN_PRIVILLAGE_FAIL"

export const GET_PAGE_ACTION = "GET_PAGE_ACTION"
export const GET_PAGE_ACTION_SUCCESS = "GET_PAGE_ACTION_SUCCESS"
export const GET_PAGE_ACTION_FAIL = "GET_PAGE_ACTION_FAIL"

export const GET_SETTINGS = "GET_SETTINGS"
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS"
export const GET_SETTINGS_FAIL = "GET_SETTINGS_FAIL"