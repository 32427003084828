import {
  GET_MAIN_PRIVILLAGE,
  GET_MAIN_PRIVILLAGE_SUCCESS,
  GET_MAIN_PRIVILLAGE_FAIL,
  GET_PAGE_ACTION,
  GET_PAGE_ACTION_SUCCESS,
  GET_PAGE_ACTION_FAIL,
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  mainPrivillageLoading: false,
  mainPrivillageData: [],
  mainPrivillageErr: {},

  pageActionLoading: false,
  pageActionData: [],
  pageActionDataErr: {},

  getSettingsLoading: false,
  getSettingsData: [],
  getSettingsErr: {},
}

const privillage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MAIN_PRIVILLAGE:
      return {
        ...state,
        mainPrivillageLoading: true,
        mainPrivillageErr: {},
      }

    case GET_MAIN_PRIVILLAGE_SUCCESS:
      return {
        ...state,
        mainPrivillageLoading: false,
        mainPrivillageData: action.payload,
        mainPrivillageErr: {},
      }

    case GET_MAIN_PRIVILLAGE_FAIL:
      return {
        ...state,
        error: {
          ...state,
          mainPrivillageLoading: false,
          mainPrivillageErr: action.payload,
        },
      }

    case GET_PAGE_ACTION:
      return {
        ...state,
        pageActionLoading: true,
        pageActionData: [],
      }

    case GET_PAGE_ACTION_SUCCESS:
      return {
        ...state,
        pageActionLoading: false,
        pageActionData: action.payload,
      }

    case GET_PAGE_ACTION_FAIL:
      return {
        ...state,
        pageActionLoading: false,
        pageActionDataErr: action.payload,
      }

    case GET_SETTINGS:
      return {
        ...state,
        getSettingsLoading: true,
        getSettingsData: [],
      }

    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        getSettingsLoading: false,
        getSettingsData: action.payload,
      }

    case GET_SETTINGS_FAIL:
      return {
        ...state,
        getSettingsLoading: false,
        getSettingsErr: action.payload,
      }

    default:
      return state
  }
}

export default privillage
