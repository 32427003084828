import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { post } from "../../helpers/axios_auth_api"
import { useSelector, useDispatch } from "react-redux"
import { getTblSafe, uploadSafeTransferImages } from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import UploadFile from "common/UploadFile"

// //Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import classes from "./index.module.scss"
import currentDate from "../../common/currentDate"

const SafeTransfer = props => {
  //meta title
  document.title = "Safe-Transfer | Bela7ma - Admin"
  const dispatch = useDispatch()

  const [safeList, setSafeList] = useState([])

  const [selectedSafeFrom, setSelectedSafeFrom] = useState("")
  const [safeFromBalance, setSafeFromBalance] = useState("")
  const [selectedSafeTo, setSelectedSafeTo] = useState("")
  const [safeToBalance, setSafeToBalance] = useState("")

  const [note, setNote] = useState("")

  const [transferValue, setTransferValue] = useState("")
  const [transferAllow, setTransferAllow] = useState(true)

  const [payNo, setPayNo] = useState("")
  const [transDate, setTransDate] = useState("")

  const [fkEmpFrom, setEmpFrom] = useState("")
  const [updateSafe, setUpdateSafe] = useState(false)

  const [selectedFiles, setselectedFiles] = useState([])
  const [resetFiles, setResetFiles] = useState(false)

  const safeListFromStore = useSelector(state => state.safeTransfer.safeList)

  useEffect(() => {
    if (!safeListFromStore.length) return
    setSafeList(safeListFromStore)
  }, [safeListFromStore])

  const fetchAllSafe = React.useCallback(() => {
    dispatch(getTblSafe())
  })
  useEffect(() => {
    fetchAllSafe()
    setEmpFrom(JSON.parse(localStorage.getItem("authUser")).pkEmpId)
    setTransDate(currentDate)
  }, [updateSafe])

  useEffect(() => {
    handleTransferValue(transferValue)
  }, [safeFromBalance])

  const handleSelectfromSafe = e => {
    setSelectedSafeFrom(e.target.value)
    handleSafeFromBalance(e.target.value)
  }

  const handleSafeFromBalance = e => {
    safeList.map(safe => {
      Number(safe.pkSafeId) === Number(e) && setSafeFromBalance(safe.balance)
      return
    })
  }

  const handleSelectToSafe = e => {
    setSelectedSafeTo(e.target.value)
    handleSafeToBalance(e.target.value)
  }

  const handleSafeToBalance = e => {
    safeList.map(safe => {
      Number(safe.id) === Number(e) && setSafeToBalance(safe.balance)
      return
    })
  }

  const handleSelectedFiles = files => {
    setselectedFiles(files)
  }

  const toggleResetFiles = value => {
    setResetFiles(value)
  }

  const handleTransferValue = e => {
    if (Number(e) > Number(safeFromBalance)) {
      setTransferAllow(false)
    } else {
      setTransferAllow(true)
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      selectedSafeFrom: selectedSafeFrom || "",
      selectedSafeTo: selectedSafeTo || "",
      transferValue: transferValue || "",
      payNo: payNo || "",
      transDate: transDate || "",
      note: note || "",
      fkEmpFrom: fkEmpFrom || "",
    },
    validationSchema: Yup.object({
      selectedSafeFrom: Yup.string().required(
        props.t("Please select Safe from")
      ),
      selectedSafeTo: Yup.string().required(props.t("Please select Safe to")),
      transferValue: Yup.number().required(
        props.t("Please Enter Transfer value")
      ),
      payNo: Yup.string().required(props.t("Please Enter Payment Number")),
    }),
    onSubmit: async values => {
      const inputDate = values["transDate"].split("T")[0]
      const inputTime = values["transDate"].split("T")[1].split(":")
      const dateParts = inputDate.split("-")
      const year = dateParts[0]
      const month = dateParts[1].padStart(2, "0")
      const day = dateParts[2].padStart(2, "0")
      const hour = inputTime[0].padStart(2, "0")
      const minute = inputTime[1].padStart(2, "0")
      const second = inputTime[2].padStart(2, "0")
      const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}`

      const newSafeTransfer = {
        fkSafeFrom: +values["selectedSafeFrom"],
        fkSafeTo: +values["selectedSafeTo"],
        value: +values["transferValue"],
        payNo: values["payNo"],
        notes: values["note"],
        transDate: formattedDate,
        fkEmpFrom: values["fkEmpFrom"],
      }

      if (transferAllow) {
        try {
          const safeTransfereRes = await postSafeTransfere(newSafeTransfer)
          if (safeTransfereRes.sts.code === 200) {
            toast.dismiss()
            toast.success(props.t("Transfer was completed successfully"))
            // upload image
            const file = selectedFiles
            if (file && file.length > 0) {
              dispatch(
                uploadSafeTransferImages({
                  safeTransferId: safeTransfereRes.rs.pkTransfereId,
                  file: file,
                })
              )
            }

            setUpdateSafe(!updateSafe)
            resetForm()
          }
        } catch (err) {
          toast.dismiss()
          toast.error(props.t("Problem occured. Please, try again"))
        }
      }
    },
  })
  const postSafeTransfere = async e => {
    const transferRes = await post("TblSafeTransfere/save", e)
    if (transferRes) {
      return transferRes
    }
  }

  const resetForm = () => {
    setSelectedSafeFrom("")
    setSafeFromBalance("")
    setSelectedSafeTo("")
    setTransferValue("")
    setPayNo("")
    setNote("")
    setselectedFiles([])
    setResetFiles(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("transfer")}
            breadcrumbItem={props.t("Safe Transfer")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label>{props.t("From Safe")}</Label>
                          <Input
                            name="selectedSafeFrom"
                            type="select"
                            onChange={e => {
                              handleSelectfromSafe(e)
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.selectedSafeFrom || ""}
                            invalid={
                              validation.touched.selectedSafeFrom &&
                              validation.errors.selectedSafeFrom
                                ? true
                                : false
                            }
                          >
                            <option value="" disabled>
                              {props.t("Select Safe From")}
                            </option>
                            {safeList.map((safe, index) => (
                              <option key={index} value={safe.pkSafeId}>
                                {safe.safeName}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.selectedSafeFrom &&
                          validation.errors.selectedSafeFrom ? (
                            <FormFeedback type="invalid">
                              {validation.errors.selectedSafeFrom}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="mb-3">
                          <Label>{props.t("safe Balance")}</Label>
                          <div className={`${classes.inputview}`}>
                            {safeFromBalance}
                          </div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label for="basicpill-lastname-input2">
                            {props.t("To")}
                          </Label>

                          <Input
                            name="selectedSafeTo"
                            type="select"
                            onChange={e => {
                              handleSelectToSafe(e)
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.selectedSafeTo || ""}
                            invalid={
                              validation.touched.selectedSafeTo &&
                              validation.errors.selectedSafeTo
                                ? true
                                : false
                            }
                          >
                            <option value="" disabled>
                              {props.t("Select Safe To")}
                            </option>
                            {safeList.map((safe, index) => (
                              <option key={index} value={safe.pkSafeId}>
                                {safe.safeName}
                              </option>
                            ))}
                          </Input>
                          {validation.touched.selectedSafeTo &&
                          validation.errors.selectedSafeTo ? (
                            <FormFeedback type="invalid">
                              {validation.errors.selectedSafeTo}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label for="transferValue">{props.t("Value")}</Label>
                          <Input
                            id="transferValue"
                            name="transferValue"
                            type="number"
                            className={`${
                              !transferAllow && "is-invalid "
                            } form-control`}
                            placeholder={props.t("Enter value")}
                            onBlur={validation.handleBlur}
                            value={validation.values.transferValue || ""}
                            invalid={
                              validation.touched.transferValue &&
                              validation.errors.transferValue
                                ? true
                                : false
                            }
                            onChange={e => {
                              setTransferValue(e.target.value)
                              handleTransferValue(e.target.value)
                            }}
                            min={0}
                          />
                          {validation.touched.transferValue &&
                          validation.errors.transferValue ? (
                            <FormFeedback type="invalid">
                              {validation.errors.transferValue}
                            </FormFeedback>
                          ) : null}
                          {!transferAllow && safeFromBalance !== "" && (
                            <div className={`${classes.invalid_message}`}>
                              {props.t("The balance is insufficient")}
                            </div>
                          )}
                        </div>
                        <div className="mb-3">
                          <Label for="payNo">{props.t("Pay No")}</Label>
                          <Input
                            id="payNo"
                            name="payNo"
                            type="number"
                            className="form-control"
                            placeholder={props.t("Enter pay no")}
                            onBlur={validation.handleBlur}
                            value={validation.values.payNo || ""}
                            invalid={
                              validation.touched.payNo &&
                              validation.errors.payNo
                                ? true
                                : false
                            }
                            onChange={e => setPayNo(e.target.value)}
                            min={0}
                          />
                          {validation.touched.payNo &&
                          validation.errors.payNo ? (
                            <FormFeedback type="invalid">
                              {validation.errors.payNo}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <Label for="note">{props.t("Note")}</Label>
                        <textarea
                          className="form-control"
                          id="note"
                          rows="5"
                          placeholder={props.t("Enter Notes")}
                          value={note}
                          onChange={e => setNote(e.target.value)}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label>{props.t("Attached Files")}</Label>
                      <Col lg="12">
                        <UploadFile
                          handleSelectedFiles={handleSelectedFiles}
                          resetFiles={resetFiles}
                          toggleResetFiles={toggleResetFiles}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col>
                        <div className="text-end">
                          <Button type="submit" color="primary">
                            {props.t("Save")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SafeTransfer.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SafeTransfer))
