// import dashboard from "store/dashboard/reducer"

// main menu and pages keys
// setting = 1 ====> For no stock
// setting = 2 ====> For stock
// setting = 3 ====> For production
const privilegePageId = {
  dashboard: {id: 27, setting: 1},
  mainProducts: {id: 1, setting: 1},
  mainPurchase: {id: 2, setting: 2},
  mainExpenses: {id: 3, setting: 1},
  mainTransfer: {id: 4, setting: 1},
  mainComponent: {id: 5, setting: 3},
  mainShift: {id: 6, setting: 1},
  mainReport: {id: 7, setting: 1},
  products: {id: 8, setting: 1},
  purchase: {id: 9, setting: 2},
  expenses: {id: 10, setting: 1},
  safeTransfer: {id: 11, setting: 1},
  stockTransfer: {id: 12, setting: 2},
  component: {id: 13, setting: 3},
  endShift: {id: 14, setting: 1},
  endShiftReport: {id: 15, setting: 1},
  stockBalance: {id: 16, setting: 2},
  safeBalance: {id: 17, setting: 1},
  supplierPayment: {id: 18, setting: 2},
  netProfit: {id: 19, setting: 1},
  monthlyReport: {id: 20, setting: 1},
  dailyReport: {id: 21, setting: 1},
  supplierAccount: {id: 22, setting: 2},
  expenseReport: {id: 23, setting: 1},
  itemsSalesReport: {id: 24, setting: 1},
  shiftReport: {id: 25, setting: 1},
  safeTransferReport: {id: 26, setting: 1},
  stockTransferReport: {id: 28, setting: 2}
}

const pageActionId = {
    products: {
        addProduct: 8,
        showProduct: 1,
        editProduct: 9,
        addCategory: 10,
        addSubCategory: 11,
        addPrepareArea: 12,
        addComponent: 13,
        addMaterial: 14,
        addUnit: 15,
        addIngrediant: {
          id: 1111, // this is dummy until add to backend
          setting: 3
        }
    }
}

const projectStockSettings = {
  noStock: 1,
  stock: 2,
  production: 3
}
export  {privilegePageId, pageActionId, projectStockSettings}
