import {
    SET_LOGO_SUCCESS,
    SET_LOGO_FAIL,
} from "./actionTypes";
import logo from "../../assets/images/kalapata_logo.png";

const INIT_STATE = {
    logo: localStorage.getItem('logo')
        ? localStorage.getItem('logo')
        : logo,
    error: {},
};

const businessSettings = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_LOGO_SUCCESS:
            return {
                ...state,
                logo: action.payload,
            };

        case SET_LOGO_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default businessSettings;