import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"

// Comment by asmaa

import Logout from "../pages/Authentication/Logout"
// import Register from "../pages/Authentication/Register";
// import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Products by Asmaa
import Dashboard from "pages/Dashboard/Dashbaord"

import Products from "../pages/Products/index"

import Purchase from "../pages/Purchase/index"

import Expenses from "pages/Expenses"

import EndShiftReport from "pages/EndShiftReport"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import SafeTransfer from "pages/SafeTransfer"
import StockTransfer from "pages/StockTransfer"
import EndShift from "pages/EndShift"
import StockBalance from "pages/Reports/StockBalance/StockBalance"
import SafeBalance from "pages/Reports/SafeBalance/SafeBalance"
import SupplierPayment from "pages/Reports/supplierPayment"
import NetProfit from "pages/Reports/NetProfit"

// new Report pages
import MonthlyReport from "pages/Reports/MonthlyReport"
import DailyReport from "pages/Reports/DailyReport"
import SupplierAccount from "pages/Reports/SupplierAccount"
import ExpenseReport from "pages/Reports/ExpenseReport"
import ItemsSalesReport from "pages/Reports/ItemsSalesReport"
import ShiftReport from "pages/Reports/ShiftReport"
import SafeTransferReport from "pages/Reports/SafeTransferReport/SafeTransferReport"

import Component from "pages/Component"

import StockTransferReport from "pages/Reports/StockTrasferReport"

import Pages404 from "../pages/Utility/pages-404"
// import Pages500 from "../pages/Utility/pages-500";
import { privilegePageId, projectStockSettings } from "common/privilege"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard />, pageId: privilegePageId.dashboard.id, stockSetting: privilegePageId.dashboard.setting },
  { path: "/products", component: <Products />, pageId: privilegePageId.products.id, stockSetting: privilegePageId.products.setting },
  { path: "/purchase", component: <Purchase />, pageId: privilegePageId.purchase.id, stockSetting: privilegePageId.purchase.setting },
  { path: "/expenses", component: <Expenses />, pageId: privilegePageId.expenses.id, stockSetting: privilegePageId.expenses.setting },
  { path: "/safe-transfer", component: <SafeTransfer />, pageId: privilegePageId.safeTransfer.id, stockSetting: privilegePageId.safeTransfer.setting },
  { path: "/stock-transfer", component: <StockTransfer />, pageId: privilegePageId.stockTransfer.id, stockSetting: privilegePageId.stockTransfer.setting },
  { path: "/component", component: <Component />, pageId: privilegePageId.component.id, stockSetting: privilegePageId.component.setting },
  { path: "/end-shift", component: <EndShift />, pageId: privilegePageId.endShift.id, stockSetting: privilegePageId.endShift.setting },
  { path: "/end-shift-report", component: <EndShiftReport />, pageId: privilegePageId.endShiftReport.id, stockSetting: privilegePageId.endShiftReport.setting },

  { path: "/reports/stock-balance", component: <StockBalance />, pageId: privilegePageId.stockBalance.id, stockSetting: privilegePageId.stockBalance.setting },
  { path: "/reports/safe-balance", component: <SafeBalance />, pageId: privilegePageId.safeBalance.id, stockSetting: privilegePageId.safeBalance.setting },
  { path: "/reports/supplier-payment", component: <SupplierPayment />, pageId: privilegePageId.supplierPayment.id, stockSetting: privilegePageId.supplierPayment.setting },
  { path: "/reports/net-profit", component: <NetProfit />, pageId: privilegePageId.netProfit.id, stockSetting: privilegePageId.netProfit.setting },
  { path: "/reports/monthly-report", component: <MonthlyReport />, pageId: privilegePageId.monthlyReport.id, stockSetting: privilegePageId.monthlyReport.setting },
  { path: "/reports/daily-report", component: <DailyReport />, pageId: privilegePageId.dailyReport.id, stockSetting: privilegePageId.dailyReport.setting },
  { path: "/reports/supplier-account", component: <SupplierAccount />, pageId: privilegePageId.supplierAccount.id, stockSetting: privilegePageId.supplierAccount.setting },
  { path: "/reports/expense-report", component: <ExpenseReport />, pageId: privilegePageId.expenseReport.id, stockSetting: privilegePageId.expenseReport.setting },
  { path: "/reports/items-sales-report", component: <ItemsSalesReport />, pageId: privilegePageId.itemsSalesReport.id, stockSetting: privilegePageId.itemsSalesReport.setting },
  { path: "/reports/shift-report", component: <ShiftReport />, pageId: privilegePageId.shiftReport.id, stockSetting: privilegePageId.shiftReport.setting },
  { path: "/reports/safe-transfer-report", component: <SafeTransferReport />, pageId: privilegePageId.safeTransferReport.id, stockSetting: privilegePageId.safeTransferReport.setting },
  { path: "/reports/stock-transfer-report", component: <StockTransferReport />, pageId: privilegePageId.stockTransferReport.id, stockSetting: privilegePageId.stockTransferReport.setting },

  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },

  //Comment By Asmaa
  // { path: "/pages-timeline", component: <PagesTimeline /> },
  // { path: "/pages-faqs", component: <PagesFaqs /> },
  // { path: "/pages-pricing", component: <PagesPricing /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/products" />,
    pageId: privilegePageId.products.id,
    stockSetting: privilegePageId.dashboard.setting
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },

  // Comment by Asmaa
  // { path: "/logout", component: <Logout /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },

  // { path: "/pages-maintenance", component: <PagesMaintenance /> },
  // { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  // { path: "/pages-404", component: <Pages404 /> },
  // { path: "/pages-500", component: <Pages500 /> },
  // { path: "/crypto-ico-landing", component: <CryptoIcoLanding /> },
]

const errorRoutes = [{ path: "*", component: <Pages404 /> }]

export { authProtectedRoutes, publicRoutes, errorRoutes }
