import { call, put, takeEvery } from "redux-saga/effects"
import { GET_MAIN_PRIVILLAGE, GET_PAGE_ACTION, GET_SETTINGS } from "./actionTypes"
import {
  getMainPrivillageSuccess,
  getMainPrivillageFail,
  getPageActionSuccess,
  getPageActionFail,
  getSettingsSuccess,
  getSettingsFail
} from "./actions"

import {
  getPageActionPrivilege,
  getUserMainMenuPrivillage,
  getSetting
} from "../../helpers/backend_helper"

function* onGetMainMenuPrivillage(params) {
  try {
    const response = yield call(getUserMainMenuPrivillage, params.payload)
    yield put(getMainPrivillageSuccess(response))
  } catch (err) {
    yield put(getMainPrivillageFail(err))
  }
}

function* onGetPageAction(params) {
  try {
    const response = yield call(getPageActionPrivilege, params.payload)
    yield put(getPageActionSuccess(response))
  } catch (err) {
    yield put(getPageActionFail(err))
  }
}

function* onGetSettings() {
  try {
    const response = yield call(getSetting)
    console.log(response)
    yield put(getSettingsSuccess(response))
  } catch (err) {
    yield put(getSettingsFail(err))
  }
}
function* privillageSaga() {
  yield takeEvery(GET_MAIN_PRIVILLAGE, onGetMainMenuPrivillage)
  yield takeEvery(GET_PAGE_ACTION, onGetPageAction)
  yield takeEvery(GET_SETTINGS, onGetSettings)
}

export default privillageSaga
