import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import Breadcrumbs from "components/Common/Breadcrumb"
import UploadFile from "common/UploadFile"
import ExpensesCategoryModal from "./Modal/ExpensesCategoryModal"

import { useDispatch, useSelector } from "react-redux"
import { addExpense, getExpenseCat } from "store/expense/actions"
import { getTblSafe } from "store/safe-transfer/actions"

import { useFormik } from "formik"

const Expenses = props => {
  //meta title
  document.title = "Expenses | Bela7ma - Admin"
  const [selectedFiles, setselectedFiles] = useState([])
  const [resetFiles, setResetFiles] = useState(false)
  const [categoryModal, setCategoryModal] = useState(false)
  const [expenseSelectedCat, setExpenseSelectedCat] = useState("")
  const allExpensesCat = useSelector(state => state.expenses.allExpensesCat)
  const expenseCatAdded = useSelector(state => state.expenses.expenseCatAdded)

  useEffect(() => {
    if (allExpensesCat && !allExpensesCat.length) {
      dispatch(getExpenseCat())
    }
    // console.log(allExpensesCat)
  }, [])

  useEffect(() => {
     if(Object.keys(expenseCatAdded).length > 0) {
      setExpenseSelectedCat(expenseCatAdded.pkPaymentCategoryID)
     }
   }, [expenseCatAdded])

  const dispatch = useDispatch()
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otherSafe: "cash",
      name: "",
      description: "",
      value: "",
      selectedPkSafeId: "",
      file: "",
      date: "",
      selectedCategory: expenseSelectedCat
    },
    validationSchema: Yup.object({
      otherSafe: Yup.string(),
      name: Yup.string().required(props.t("Expense Name is required")),
      description: Yup.string().required(props.t("Expense Note is required")),
      value: Yup.number().required(props.t("Expense Value is required")),
      selectedPkSafeId: Yup.mixed().when("otherSafe", {
        is: val => val === "otherSafe",
        then: Yup.number().required(props.t("Please select a value")),
      }),
      file: Yup.array(),
      date: Yup.string().required(props.t("Please enter date")),
      selectedCategory: Yup.number().required(
        props.t("Please select category")
      ),
    }),
    onSubmit: values => {
      let bodyData = {
        payName: values.name,
        description: values.description,
        value: values.value,
        fK_Safe_ID: JSON.parse(localStorage.getItem("authUser")).fkSafeId,
        fK_Shift_ID: JSON.parse(localStorage.getItem("shiftID")),
        return_pay: false,
        buyBill: false,
        file: selectedFiles.length === 0 ? null : selectedFiles,
        docDate: values.date,
        fK_PaymentCategory_ID: values.selectedCategory
      }
      if (values.otherSafe === "otherSafe")
        bodyData.fK_Safe_ID = +values.selectedPkSafeId
      dispatch(addExpense(bodyData))
      const storedOtherSafe = validation.values.otherSafe
      validation.resetForm()
      setExpenseSelectedCat("")
      setResetFiles(true)

      validation.setFieldValue("otherSafe", storedOtherSafe)
      setselectedFiles([])
    },
  })

  // Fetch tabel safe data
  useEffect(() => {
    if (validation.values.otherSafe === "cash") return
    dispatch(getTblSafe())
  }, [validation.values.otherSafe])

  const tabelData = useSelector(state => state.safeTransfer.safeList)

  const handleSafe = e => {
    validation.setValues({
      ...validation.values,
      otherSafe: e.target.value,
    })
  }

  const handleSelectedFiles = files => {
    setselectedFiles(files)
  }

  const toggleResetFiles = value => {
    setResetFiles(value)
  }
  const handleCategoryModal = () => {
    setCategoryModal(true)
  }
  const handleCategoryToggle = () => {
    setCategoryModal(!categoryModal)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Expenses")}
            breadcrumbItem={props.t("Add Expenses")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="name" className="col-form-label col-sm-2">
                        {props.t("Name")}
                      </Label>
                      <Col sm="10">
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder={props.t("Enter Name")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="name" className="col-form-label col-sm-2">
                        {props.t("Category")}
                      </Label>
                      <Col sm="10">
                        <div className="input-group">
                          <div className="col-10">
                            <Input
                              name="selectedCategory"
                              type="select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.selectedCategory || ""}
                              invalid={
                                validation.touched.selectedCategory &&
                                validation.errors.selectedCategory
                                  ? true
                                  : false
                              }
                            >
                              <option value="" disabled>
                                {props.t("selectCategory")}
                              </option>
                              {allExpensesCat &&allExpensesCat.length > 0 &&
                                allExpensesCat.map((cat, index) => (
                                  <option
                                    key={index}
                                    value={cat.pkPaymentCategoryID}
                                  >
                                    {cat.paymentCategoryName}
                                  </option>
                                ))}
                            </Input>
                            {validation.touched.selectedCategory &&
                            validation.errors.selectedCategory ? (
                              <FormFeedback type="invalid">
                                {validation.errors.selectedCategory}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="col-2">
                            <button
                              className="btn btn-primary w-100"
                              style={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                padding: "7px 0",
                              }}
                              type="button"
                              onClick={handleCategoryModal}
                            >
                              {props.t("Add")}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="description"
                        className="col-form-label col-sm-2"
                      >
                        {props.t("Note")}
                      </Label>
                      <Col sm="10">
                        <Input
                          id="description"
                          name="description"
                          type="textarea"
                          placeholder={props.t("Enter Notes")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          invalid={
                            validation.touched.description &&
                            validation.errors.description
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="value"
                        className="col-form-label col-sm-2"
                      >
                        {props.t("Value")}
                      </label>
                      <Col sm="10">
                        <Input
                          id="value"
                          name="value"
                          type="number"
                          placeholder={props.t("Enter Value")}
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.value || ""}
                          invalid={
                            validation.touched.value && validation.errors.value
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="value"
                        className="col-form-label col-sm-2"
                      >
                        {props.t("Bill Date")}
                      </label>
                      <Col sm="10">
                        <Input
                          id="date"
                          name="date"
                          type="date"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.date || ""}
                          invalid={
                            validation.touched.date && validation.errors.date
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="value"
                        className="col-form-label col-sm-2"
                      >
                        {props.t("Safe")}
                      </label>
                      <Col sm={6}>
                        <div className="">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="safe"
                            id="cash"
                            value="cash"
                            checked={validation.values.otherSafe === "cash"}
                            onChange={handleSafe}
                          />
                          <label
                            className="form-check-label px-3"
                            htmlFor="cash"
                          >
                            {props.t("Cash")}
                          </label>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="safe"
                            id="exampleRadios2"
                            value="otherSafe"
                            checked={validation.values.otherSafe === "otherSafe"}
                            onChange={handleSafe}
                          />
                          <label
                            className="form-check-label px-3"
                            htmlFor="exampleRadios2"
                          >
                            {props.t("Other Safe")}
                          </label>
                        </div>
                      </Col>
                    </FormGroup>
                    {validation.values.otherSafe === "otherSafe" && (
                      <FormGroup row>
                        <Col sm={2}>
                          <label className="col-form-label">
                            {props.t("Select Safe Name")}
                          </label>
                        </Col>
                        <Col sm={10}>
                          <Input
                            id="selectedPkSafeId"
                            name="selectedPkSafeId"
                            type="select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.selectedPkSafeId || ""}
                            invalid={
                              validation.touched.selectedPkSafeId &&
                              validation.errors.selectedPkSafeId
                                ? true
                                : false
                            }
                          >
                            <option>{props.t("Select Safe Name")}</option>
                            {tabelData &&
                              tabelData.length > 0 &&
                              tabelData
                                .filter(
                                  one =>
                                    one.safe_type.toLowerCase() === "visa" ||
                                    one.safe_type.toLowerCase() === "company"
                                )
                                .map((one, i) => (
                                  <option value={one.pkSafeId} key={i}>
                                    {one.safeName}
                                  </option>
                                ))}
                          </Input>
                        </Col>
                      </FormGroup>
                    )}

                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Attached Files")}
                      </Label>
                      <Col lg="10">
                        <UploadFile
                          handleSelectedFiles={handleSelectedFiles}
                          resetFiles={resetFiles}
                          toggleResetFiles={toggleResetFiles}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <div className="text-end">
                          <Button type="submit" color="primary">
                            {props.t("Save")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ExpensesCategoryModal
            categoryModal={categoryModal}
            handleCategoryToggle={handleCategoryToggle}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

Expenses.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Expenses))
