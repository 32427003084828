import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Spinner } from "reactstrap"
import SpineArea from "pages/Dashboard/components/revenue/SplineArea"
import classes from "../../index.module.scss"
import SelectPeriodType from "pages/Dashboard/components/revenue/SelectPeriodType"
import {
  SpineAreaMonthDummyDataDummyData,
  SpineAreaTodayDummyDataDummyData,
  SpineAreaWeekDummyDataDummyData,
  spineAreaColors,
} from "pages/Dashboard/data"

import incomeChart from "../../svgs/incomeChart.svg"
import expenseChart from "../../svgs/expenseChart.svg"

import {
  getRevenueMonthly,
  getRevenueWeekly,
  getRevenueDaily,
  getRevenueInOut,
} from "store/actions"

const labels = [
  {
    backgroundColor: spineAreaColors.incomeColor,
    name: "Income",
  },
  {
    backgroundColor: spineAreaColors.expenseColor,
    name: "Outcome",
  },
]

const svgs = [
  {
    name: "Income",
    svgSrc: incomeChart,
    money: "$126,000",
  },
  {
    name: "Outcome",
    svgSrc: expenseChart,
    money: "$126,000",
  },
]

const Revenue = props => {
  const dispatch = useDispatch()

  const [periodData, setPeriodData] = useState({})
  const [periodType, setPeriodType] = useState("monthly")

  const { revenueDataList: revenueData, revenueDataLoading: isLoading } =
    useSelector(state => state.dashboard)

  const currentPeriodTypeHandler = periodType => {
    if (periodType === "today") {
      // setPeriodData(SpineAreaTodayDummyDataDummyData)
      setPeriodType("today")
      dispatch(
        getRevenueInOut({
          InOutComePeriod: "InOutComeDaily",
          InOutComePeriodValue: true,
        })
      )
    } else if (periodType === "monthly") {
      // setPeriodData(SpineAreaMonthDummyDataDummyData)
      setPeriodType("monthly")
      dispatch(
        getRevenueInOut({
          InOutComePeriod: "InOutComeMonthly",
          InOutComePeriodValue: true,
        })
      )
    } else if (periodType === "weekly") {
      // setPeriodData(SpineAreaWeekDummyDataDummyData)
      setPeriodType("weekly")
      dispatch(
        getRevenueInOut({
          InOutComePeriod: "InOutComeWeekly",
          InOutComePeriodValue: true,
        })
      )
    }
  }

  useEffect(() => {
    setPeriodType("monthly")
    dispatch(
      getRevenueInOut({
        InOutComePeriod: "InOutComeMonthly",
        InOutComePeriodValue: true,
      })
    )
  }, [])
  useEffect(() => {
    setPeriodData({
      yaxisData: [
        {
          name: "Income",
          color: spineAreaColors.incomeColor,
          data: Object.keys(revenueData).length ? revenueData.incomeYaxis : [],
        },
        {
          name: "Outcome",
          color: spineAreaColors.expenseColor,
          data: Object.keys(revenueData).length ? revenueData.outComeYaxis : [],
        },
      ],
      xaxisData: Object.keys(revenueData).length ? revenueData.xaxisData : [],
    })
  }, [revenueData])
  return (
    <Card style={{ borderRadius: "16px" }}>
      <CardBody className={classes.revenueCardBody}>
        <div className="d-sm-flex flex-wrap">
          <div className="d-flex flex-column mb-4">
            <h4 className={"card-title" + classes.customerMapTitle}>{props.t("Revenue")}</h4>
            <p className={classes.customerMapDescription}>
              {props.t("optimizingRevenue")}.
            </p>
          </div>
          <SelectPeriodType
            currentPeriodTypeHandler={currentPeriodTypeHandler}
            periodType={periodType}
          />
        </div>
        <div className="d-flex align-items-end justify-content-between mb-4">
          <div className="d-flex align-items-center gap-4">
            {labels.map((one, index) => (
              <div key={index} className="d-flex align-items-center gap-2">
                <span
                  className={classes.revnueLabel}
                  style={{ backgroundColor: one.backgroundColor }}
                ></span>
                <span className={classes.revnueLabelText}>{one.name}</span>
              </div>
            ))}
          </div>
          <div className="d-flex align-items-center gap-4">
            {svgs.map((one, index) => (
              <div
                className="d-flex align-items-center flex-column"
                key={index}
              >
                <span className={classes.revnueSvgTitle}>{one.name}</span>
                <div className="d-flex align-items-center gap-2">
                  <img
                    src={one.svgSrc}
                    alt="Income"
                    className={classes.revnueChart}
                  />
                  {isLoading ? (
                    <div className="text-center">
                      <Spinner className="ms-2" color="primary" />
                    </div>
                  ) : (
                    <h3 className={classes.revnueMoney}>
                      {one.name === "Income"
                        ? Object.keys(revenueData).length &&
                        parseFloat(revenueData.incomeYaxis.reduce(
                            (acc, curr) => acc + curr,
                            0
                          )).toFixed(2)
                        : Object.keys(revenueData).length &&
                        parseFloat(revenueData.outComeYaxis.reduce(
                            (acc, curr) => acc + curr,
                            0
                          )).toFixed(2)}
                    </h3>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {Object.keys(periodData).length > 0 && (
          isLoading ? (
            <div className="text-center">
              <Spinner className="ms-2 " color="primary" />
            </div>) : <SpineArea
            dataColors={`["${spineAreaColors.expenseColor}", "${spineAreaColors.incomeColor}"]`}
            data={periodData.yaxisData}
            periodData={periodData}
            periodType={periodType}
          />
        )}
      </CardBody>
    </Card>
  )
}

Revenue.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Revenue))
