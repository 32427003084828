import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  FormGroup,
  Button,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { useFormik } from "formik"
import * as Yup from "yup"
// import UnitModal from "./UnitModal";
import { toast } from "react-toastify"
// import { postMaterial } from "../../../store/products/materials/actions";
import {
  getUnits,
  resetPostUnit,
  resetModelType,
  postMaterial,
  updateMaterialComponent,
} from "store/actions"

const MaterialDetails = props => {
  const dispatch = useDispatch()
  const { handleMaterialToggle, materialModal } = props

  const materialErr = useSelector(state => state.materials.error)
  const units = useSelector(state => state.units.allUnits)

  const materials = useSelector(state => state.materials)
  const isUpdateMaterialComponent = useSelector(state => state.materials.updateMaterialComponent)

  const { oneMaterial, allMaterials } = materials

  const [materialName, setMaterialName] = useState("")
  const [selectedUnit, setSelectedUnit] = useState("")
  const [minOrder, setMinOrder] = useState(0)
  const [unit, setUnit] = useState([])

  const [isEditing, setIsEditing] = useState(false)
  const [PK_Material_ID, setPK_Material_ID] = useState("")
  const [materialPrice, setMaterialPrice] = useState(0)
  const [materialType, setMaterialType] = useState("")

  const [materialList, setMaterialList] = useState([])
  const [matNameExist, setMatNameExist] = useState(false)

  const toggle = () => {
    handleMaterialToggle()
  }

  // display data
  useEffect(() => {
    resetField()
    if (oneMaterial.length) {
      setMaterialName(oneMaterial[0].MaterialName ? oneMaterial[0].MaterialName : "")
      setSelectedUnit(oneMaterial[0].fk_unit_ID ? oneMaterial[0].fk_unit_ID : "")
      setMinOrder(oneMaterial[0].Mindemand ? oneMaterial[0].Mindemand : 0)
      setPK_Material_ID(oneMaterial[0].PK_Material_ID ? oneMaterial[0].PK_Material_ID : "")
      setMaterialPrice(oneMaterial[0].MatPrice ? oneMaterial[0].MatPrice : 0)
      setMaterialType(oneMaterial[0].MatType ? oneMaterial[0].MatType : "")
    }
  }, [oneMaterial, materialModal])

  // Set all materials to check if material exist before adding
  useEffect(() => {
    let materialListCopy = []
    allMaterials &&
      allMaterials.map(
        item =>
          !item.material && item.materialName && materialListCopy.push(item)
      )
    setMaterialList(materialListCopy)
  }, [materials])

  useEffect(() => {
    if (units && !units.length) {
    }
    dispatch(getUnits())
  }, [])

  useEffect(() => {
    setUnit(units)
  }, [units])

  useEffect(() => {
    if (isUpdateMaterialComponent) {
      resetField()
      // oneMaterial && (oneMaterial[0].MatType === "Raw" && toggle())
      materialModal && toggle()
    }
  }, [isUpdateMaterialComponent])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      materialName: materialName || "",
      selectedUnit: selectedUnit || "",
      minOrder: minOrder || "",
      PK_Material_ID: PK_Material_ID || "",
      materialPrice: materialPrice || "",
      materialType: materialType || "",
    },
    validationSchema: Yup.object({
      materialName: Yup.string().required(props.t("Please Enter Material Name")),
      selectedUnit: Yup.string().required(props.t("Please select unit")),
      minOrder: Yup.number().required(props.t("Please enter min. order")),
      materialPrice: Yup.number().required(props.t("Please enter material price")),
    }),
    onSubmit: async values => {
      const newMaterial = {
        materialName: values["materialName"],
        PK_Material_ID: Number(values["PK_Material_ID"]),
        mindemand: Number(values["minOrder"]),
        fK_Unit_ID: Number(values["selectedUnit"]),
        component: false,
        matPrice: Number(values["materialPrice"]),
      }
      if(!matNameExist) {

        try {
          dispatch(updateMaterialComponent(newMaterial))
          
        } catch (err) {
          console.log(err)
        }
      }
    },
  })

  const handleCancel = () => {
    toggle()
  }

  function resetField() {
    setSelectedUnit("")
    setIsEditing(false)
    setMaterialName("")
    setMinOrder(0)
    setMaterialPrice(0)
  }

  const handleBlurName = e => {
    setMatNameExist(false)
    materialList &&
      materialList.map(
        material =>
        material.materialName.trim() !== "" &&
        material.materialName.trim().toLowerCase() === e.trim().toLowerCase() &&
        material.materialName.trim().toLowerCase() !== oneMaterial[0].MaterialName.toLowerCase() &&
        setMatNameExist(true)
      )
  }

  return (
    <Modal isOpen={materialModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Show Material")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Raw Material")}</Label>
                <Input
                  name="materialName"
                  type="text"
                  placeholder={props.t("Enter Raw Material")}
                  onChange={e => setMaterialName(e.target.value)}
                  onBlur={validation.handleBlur}
                  onBlurCapture={e => handleBlurName(e.target.value)}
                  value={validation.values.materialName || ""}
                  invalid={
                    validation.touched.materialName &&
                    validation.errors.materialName
                      ? true
                      : false
                  }
                  disabled={isEditing ? false : true}
                />
                {validation.touched.materialName &&
                validation.errors.materialName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.materialName}
                  </FormFeedback>
                ) : null}
                {matNameExist ? <span className="invalid-feedback d-block">{props.t("Material name already exist")}</span> : null}
              </div>
            </Col>
            {/* <Col sm={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Price")}</Label>
                <div className="input-group">
                  <Input
                    name="materialPrice"
                    type="number"
                    step="any"
                    onChange={e => setMaterialPrice(e.target.value)}
                    onBlur={validation.handleBlur}
                    value={validation.values.materialPrice || ""}
                    invalid={
                      validation.touched.materialPrice && validation.errors.materialPrice
                        ? true
                        : false
                    }
                    min={0}
                    disabled
                  />
                  {validation.touched.materialPrice && validation.errors.materialPrice ? (
                    <FormFeedback type="invalid">
                      {validation.errors.materialPrice}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col> */}
          
            <Col sm={6}>
              <label className="form-label">{props.t("Default Unit")}</label>
              <div className="input-group">
                <Col xs={12}>
                  <Input
                    name="selectedUnit"
                    type="select"
                    onChange={e => {
                      setSelectedUnit(e.target.value)
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.selectedUnit || ""}
                    invalid={
                      validation.touched.selectedUnit &&
                      validation.errors.selectedUnit
                        ? true
                        : false
                    }
                    disabled
                  >
                    <option value="">{props.t("Select")}</option>
                    {unit.map((unit, index) => (
                      <option key={index} value={unit.pkUnitId}>
                        {unit.unit}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.selectedUnit &&
                  validation.errors.selectedUnit ? (
                    <FormFeedback type="invalid">
                      {validation.errors.selectedUnit}
                    </FormFeedback>
                  ) : null}
                </Col>

                {validation.touched.unit && validation.errors.unit ? (
                  <FormFeedback type="invalid">
                    {validation.errors.unit}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col sm={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Minimum Order")}</Label>
                <div className="input-group">
                  <Input
                    name="minOrder"
                    type="number"
                    placeholder={props.t("Enter Minimum Order")}
                    onChange={e => setMinOrder(e.target.value)}
                    onBlur={validation.handleBlur}
                    value={validation.values.minOrder || ""}
                    invalid={
                      validation.touched.minOrder && validation.errors.minOrder
                        ? true
                        : false
                    }
                    min={0}
                    disabled={isEditing ? false : true}
                  />
                  {validation.touched.minOrder && validation.errors.minOrder ? (
                    <FormFeedback type="invalid">
                      {validation.errors.minOrder}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                {isEditing && (
                  <button type="submit" className="btn btn-success save-user">
                    <i className="mdi mdi-check-circle-outline me-1" />
                    {props.t("Save")}
                  </button>
                )}
                {!isEditing &&<Button
                  onClick={() => setIsEditing(true)}
                  className="btn btn-warning mx-1"
                >
                  <i className="mdi mdi-pencil me-1" />
                  {props.t("Edit")}
                </Button>}
                <Button
                  type="button"
                  color="secondary"
                  className="mx-1"
                  onClick={handleCancel}
                >
                  <i className="mdi mdi-cancel me-1" />
                  {props.t("Cancel")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

MaterialDetails.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(MaterialDetails))
